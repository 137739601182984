import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  Home,
} from "react-feather";

import {
  Description as DescriptionIcon,
  Event as EventIcon,
  Build as BuildIcon,
} from "@material-ui/icons/";

// URLS
import {
  HOME_PAGE_URL,
  SIGN_IN_PAGE_URL,
  ADMIN_HOME_URL,
  ABOUT_US_PAGE_URL,
  CONTACT_US_PAGE_URL,
  EVENTS_UPCOMING_EVENTS_PAGE_URL,
  YOGA_TTC_PAGE_URL,
  AOI_NOV_2022_PAGE_URL,
  FACILITATOR_TRAINING_2023_PAGE_URL,
  GOA_EVENTS_PAGE_URL,
  PRIVACY_PAGE_URL,
  TERMS_AND_CONDITIONS_PAGE_URL,
  EVENTS_PAGE_URL,
  EVENTS_PAST_EVENTS_LIST_PAGE_URL,
  EVENTS_PAST_EVENTS_CATEGORY_PAGE_URL,
  EVENTS_PAST_EVENT_DETAIL_PAGE_URL,
  FUNDRAISER_2022_PAGE_URL,
  UNSUBSCRIBE_LANDING_PAGE_URL,
  TOOLS_PAGE_URL,
  CIFT_FEEDBACK_PAGE_URL,
  DHARAMSHALA_2023_FEEDBACK_PAGE_URL,
  MAUNA_2023_PAGE_URL,
  ELLA_2023_PAGE_URL,
  AUROVILLE_APR_2023_PAGE_URL,
  MOUNTAIN_MOVEMENT_FESTIVAL_PAGE_URL,
  MOUNTAIN_MOVEMENT_FESTIVAL_JUNE_2024_PAGE_URL,
  MOVEMENT_PLUS_FESTIVAL_PAGE_URL,
  AOI_2023_PAGE_URL,
  AOI_2024_PAGE_URL,
  AOI_2025_PAGE_URL,
  TRAVELLING_QUESTIONS_PAGE_URL,
  HARVEST_2024_PAGE_URL,
  IN_RESONANCE_JAMS_2024_PAGE_URL,
  SUPPORT_US_PAGE_URL,
} from "../constants/urls";

// Guards
import AuthGuard from "../components/AuthGuard";
import AdminAuthGuard from "../components/AdminAuthGuard";
import MoverAuthGuard from "../components/MoverAuthGuard";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Components components
const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const SaaS = async(() => import("../pages/dashboards/SaaS"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));
const Formik = async(() => import("../pages/forms/Formik"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
const Orders = async(() => import("../pages/pages/Orders"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Profile = async(() => import("../pages/pages/Profile"));
const Settings = async(() => import("../pages/pages/Settings"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));
const Chat = async(() => import("../pages/pages/Chat"));

// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));
const DataGrid = async(() => import("../pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Welcome = async(() => import("../pages/docs/Welcome"));
const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
const EnvironmentVariables = async(
  () => import("../pages/docs/EnvironmentVariables")
);
const Deployment = async(() => import("../pages/docs/Deployment"));
const Theming = async(() => import("../pages/docs/Theming"));
const StateManagement = async(() => import("../pages/docs/StateManagement"));
const APICalls = async(() => import("../pages/docs/APICalls"));
const ESLintAndPrettier = async(
  () => import("../pages/docs/ESLintAndPrettier")
);
const Support = async(() => import("../pages/docs/Support"));
const Changelog = async(() => import("../pages/docs/Changelog"));

// Presentation Pages
// const OldHomePage = async(() => import("../pages/presentation/home"));
// const AboutPage = async(() => import("../pages/presentation/about"));
// const AoiNov2021Page = async(
//   () => import("../pages/presentation/events/aoi_nov_2021")
// );
// const Landing = async(() => import("../pages/presentation/Landing"));
// const SubscribePage = async(() => import("../pages/presentation/subscribe"));

// Public Pages
const HomePage = async(() => import("../pages/public/home"));
const AboutUsPage = async(() => import("../pages/public/about-us/"));
const ContactUsPage = async(() => import("../pages/public/contact-us/"));
const SupportUsPage = async(() => import("../pages/public/support-us/"));
const UnsubscribeLandingPage = async(
  () => import("../pages/public/unsubscribe-landing")
);

const Mauna2023Page = async(() => import("../pages/public/events/mauna-2023"));
const Ella2023Page = async(() => import("../pages/public/events/ella-2023"));
const AurovilleApr2023Page = async(
  () => import("../pages/public/events/auroville-apr-2023")
);
const MountainMovementFestivalPage = async(
  () => import("../pages/public/events/mountain-movement-festival")
);

const MovementPlusFestivalPage = async(
  () => import("../pages/public/events/movement-plus-festival")
);

const AOINov2022Page = async(
  () => import("../pages/public/events/aoi-nov-2022")
);
const FacilitatorTraining2023Page = async(
  () => import("../pages/public/events/facilitator-training-2023")
);

const AOI2023Page = async(() => import("../pages/public/events/aoi-2023"));

const AOI2024Page = async(() => import("../pages/public/events/aoi-2024"));

const AOI2025Page = async(() => import("../pages/public/events/aoi-2025"));

const InResonanceJams2024Page = async(
  () => import("../pages/public/events/in-resonance-jams-2024")
);

const MountainMovementFestivalJune2024Page = async(
  () => import("../pages/public/events/mountain-movement-festival-june-2024")
);

const TravellingQuestionsPage = async(
  () => import("../pages/public/events/travelling-questions")
);

const Harvest2024Page = async(
  () => import("../pages/public/events/harvest-2024")
);

const YogaTTCPage = async(() => import("../pages/public/events/yoga-ttc"));
const GoaEventsPage = async(() => import("../pages/public/events/goa-events"));
const PrivacyAndCookiesPage = async(
  () => import("../pages/public/privacy-and-cookies")
);
const TermsAndConditionsPage = async(
  () => import("../pages/public/terms-and-conditions")
);

const PublicEventsPage = async(() => import("../pages/public/events/"));

const PublicPastEventListPage = async(
  () => import("../pages/public/events/past-events/past-events-list")
);

const PublicPastEventsCategoryPage = async(
  () => import("../pages/public/events/past-events/past-events-category")
);

const FundRaiser2022Page = async(
  () => import("../pages/public/events/fundraiser-2022")
);

const PublicUpcomingEventsPage = async(
  () => import("../pages/public/events/upcoming-events")
);

const PublicPastEventDetailPage = async(
  () => import("../pages/public/events/past-events/past_event_detail")
);

const ToolsPage = async(() => import("../pages/public/tools"));

const CIFTFeedbackPage = async(
  () => import("../pages/public/tools/cift-feedback")
);

const Dharamshala2023FeedbackPage = async(
  () => import("../pages/public/tools/dharamshala-2023-feedback")
);

// Mover Authenticated Only Pages
const MoverProfile = async(() => import("../pages/mover/Profile"));

// Admin Authenticated Only Pages
const AdminHome = async(() => import("../pages/admin/Home"));

const Diagnostics = async(() => import("../pages/admin/Diagnostics"));

const UnregisteredUsers = async(
  () => import("../pages/admin/users/UnregisteredUsers")
);
const RegisteredUsers = async(
  () => import("../pages/admin/users/RegisteredUsers")
);
const AddUnregisteredUser = async(
  () => import("../pages/admin/users/AddUnregisteredUser")
);
const EditUnregisteredUser = async(
  () => import("../pages/admin/users/EditUnregisteredUser")
);
const EditRegisteredUser = async(
  () => import("../pages/admin/users/EditUnregisteredUser")
);

const MyPosts = async(() => import("../pages/admin/posts/MyPosts"));
const AddNewPost = async(() => import("../pages/admin/posts/AddNewPost"));
const EditPost = async(() => import("../pages/admin/posts/EditPost"));
const EventRegistrationDesk = async(
  () => import("../pages/admin/events/event-registration-desk")
);
const AllEvents = async(() => import("../pages/admin/events/AllEvents"));
const AddNewEvent = async(() => import("../pages/admin/events/AddNewEvent"));
const EditEvent = async(() => import("../pages/admin/events/EditEvent"));
const EventDetails = async(() => import("../pages/admin/events/EventDetails"));
const EventPhotos = async(() => import("../pages/admin/events/event-photos"));
const EventInterestSubmissions = async(
  () => import("../pages/admin/events/interest-submissions")
);
const ConfigureAsUpcomingEvent = async(
  () => import("../pages/admin/events/configure-as-upcoming-event")
);
const ConfigureEventPoster = async(
  () => import("../pages/admin/events/configure-event-poster")
);

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const moverProfileRoutes = {
  id: "Profile",
  path: "/mover/profile",
  component: MoverProfile,
  children: null,
  guard: MoverAuthGuard,
};

const adminHomeRoutes = {
  id: "Home",
  path: ADMIN_HOME_URL,
  icon: <Home />,
  children: null,
  component: AdminHome,
  guard: AdminAuthGuard,
};

const diagnosticsRoute = {
  id: "Diagnostics",
  path: "/admin/diagnostics",
  icon: <BuildIcon />,
  children: null,
  component: Diagnostics,
  guard: AdminAuthGuard,
};

const adminUsersRoutes = {
  id: "Users",
  path: "/admin/users",
  icon: <Users />,
  children: [
    {
      path: "/admin/users/registered",
      name: "Registered Users",
      component: RegisteredUsers,
      guard: AdminAuthGuard,
    },
    {
      path: "/admin/users/unregistered",
      name: "Unregistered Users",
      component: UnregisteredUsers,
      guard: AdminAuthGuard,
    },
    {
      path: "/admin/users/unregistered/add",
      name: "Add Unregistered User",
      component: AddUnregisteredUser,
      guard: AdminAuthGuard,
    },
  ],
  component: null,
};

const adminUsersEditRegisteredUser = {
  id: "EditRegisteredUser",
  path: "/admin/users/registered/edit",
  component: EditRegisteredUser,
  children: null,
  guard: AdminAuthGuard,
};

const adminUsersEditUnregisteredUser = {
  id: "EditUnregisteredUser",
  path: "/admin/users/unregistered/edit",
  component: EditUnregisteredUser,
  children: null,
  guard: AdminAuthGuard,
};

const adminPostsRoutes = {
  id: "Posts",
  path: "/admin/posts",
  icon: <DescriptionIcon />,
  children: [
    {
      path: "/admin/posts/my-posts",
      name: "My Posts",
      component: MyPosts,
      guard: AdminAuthGuard,
    },
    {
      path: "/admin/posts/add",
      name: "Add New Post",
      component: AddNewPost,
      guard: AdminAuthGuard,
    },
  ],
  component: null,
};

const adminPostsEditRoute = {
  id: "EditPost",
  path: "/admin/posts/edit",
  component: EditPost,
  children: null,
  guard: AdminAuthGuard,
};

const adminEventsRoutes = {
  id: "Events",
  path: "/admin/events",
  icon: <EventIcon />,
  children: [
    {
      path: "/admin/events/all-events",
      name: "All Events",
      component: AllEvents,
      guard: AdminAuthGuard,
    },
    {
      path: "/admin/events/add",
      name: "Add New Event",
      component: AddNewEvent,
      guard: AdminAuthGuard,
    },
  ],
  component: null,
};

const adminEventsEditRoute = {
  id: "EditEvent",
  path: "/admin/events/edit",
  component: EditEvent,
  children: null,
  guard: AdminAuthGuard,
};

const adminEventsEventDetailsRoute = {
  id: "EventDetails",
  path: "/admin/events/event",
  component: EventDetails,
  children: null,
  guard: AdminAuthGuard,
};

const adminEventsEventPhotosRoute = {
  id: "EventPhotos",
  path: "/admin/events/event/event-photos",
  component: EventPhotos,
  children: null,
  guard: AdminAuthGuard,
};

const adminEventsEventInterestSubmissionsRoute = {
  id: "EventInterestSubmissions",
  path: "/admin/events/event/interest-submissions",
  component: EventInterestSubmissions,
  children: null,
  guard: AdminAuthGuard,
};

//

const adminEventsConfigureAsUpcomingEventRoute = {
  id: "ConfigureAsUpcomingEvent",
  path: "/admin/events/event/configure-as-upcoming-event",
  component: ConfigureAsUpcomingEvent,
  children: null,
  guard: AdminAuthGuard,
};

const adminEventsConfigureEventPosterRoute = {
  id: "ConfigureEventPoster",
  path: "/admin/events/event/configure-event-poster",
  component: ConfigureEventPoster,
  children: null,
  guard: AdminAuthGuard,
};

const adminEventsEventRegistrationDeskRoute = {
  id: "EventRegistrationDesk",
  path: "/admin/events/event-registration-desk",
  component: EventRegistrationDesk,
  children: null,
  guard: AdminAuthGuard,
};

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard",
      name: "Default",
      component: Default,
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: Analytics,
    },
    {
      path: "/dashboard/saas",
      name: "SaaS",
      component: SaaS,
    },
  ],
  component: null,
};

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/pages/chat",
      name: "Chat",
      component: Chat,
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank,
    },
  ],
  component: null,
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  children: null,
};

const invoiceRoutes = {
  id: "Invoices",
  path: "/invoices",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoices",
      name: "List",
      component: InvoiceList,
    },
    {
      path: "/invoices/detail",
      name: "Details",
      component: InvoiceDetails,
    },
  ],
  component: null,
};

const orderRoutes = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: Orders,
  children: null,
};

const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  component: Tasks,
  children: null,
};

const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  component: Calendar,
  children: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: SIGN_IN_PAGE_URL,
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const componentsRoutes = {
  id: "Components",
  path: "/components",
  header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/alerts",
      name: "Alerts",
      component: Alerts,
    },
    {
      path: "/components/avatars",
      name: "Avatars",
      component: Avatars,
    },
    {
      path: "/components/badges",
      name: "Badges",
      component: Badges,
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "/components/cards",
      name: "Cards",
      component: Cards,
    },
    {
      path: "/components/chips",
      name: "Chips",
      component: Chips,
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      component: Dialogs,
    },
    {
      path: "/components/expansion-panels",
      name: "Expansion Panels",
      component: ExpPanels,
    },
    {
      path: "/components/lists",
      name: "Lists",
      component: Lists,
    },
    {
      path: "/components/menus",
      name: "Menus",
      component: Menus,
    },
    {
      path: "/components/pagination",
      name: "Pagination",
      component: Pagination,
    },
    {
      path: "/components/progress",
      name: "Progress",
      component: Progress,
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      component: Snackbars,
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      component: Tooltips,
    },
  ],
  component: null,
};

const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      component: Pickers,
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      component: SelectionCtrls,
    },
    {
      path: "/forms/selects",
      name: "Selects",
      component: Selects,
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      component: TextFields,
    },
    {
      path: "/forms/dropzone",
      name: "Dropzone",
      component: Dropzone,
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors,
    },
    {
      path: "/forms/formik",
      name: "Formik",
      component: Formik,
    },
  ],
  component: null,
};

const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      component: SimpleTable,
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      component: AdvancedTable,
    },
    {
      path: "/tables/data-grid",
      name: "Data Grid",
      component: DataGrid,
    },
  ],
  component: null,
};

const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      component: MaterialIcons,
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      component: FeatherIcons,
    },
  ],
  component: null,
};

const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  children: null,
};

const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps,
    },
  ],
  component: null,
};

// const landingRoutes = {
//   id: "Landing Page",
//   path: "/landing",
//   header: "Docs",
//   icon: <Monitor />,
//   component: Landing,
//   children: null,
// };

// const oldaHomePageRoute = {
//   id: "Home Page",
//   path: "/",
//   component: OldHomePage,
//   children: null,
// };

// const aboutPageRoute = {
//   id: "About Page",
//   path: "/about",
//   component: AboutPage,
//   children: null,
// };

// const aoiNov2021PageRoute = {
//   id: "Aoi Nov 2021 Page",
//   path: "/events/aoi_nov_2021",
//   component: AoiNov2021Page,
//   children: null,
// };

// const subscribePageRoute = {
//   id: "Subscribe Page",
//   path: "/subscribe",
//   component: SubscribePage,
//   children: null,
// };

const homePageRoute = {
  id: "Home Page",
  path: HOME_PAGE_URL,
  component: HomePage,
  children: null,
};

const aboutUsPageRoute = {
  id: "AboutUs Page",
  path: ABOUT_US_PAGE_URL,
  component: AboutUsPage,
  children: null,
};

const contactUsPageRoute = {
  id: "ContactUs Page",
  path: CONTACT_US_PAGE_URL,
  component: ContactUsPage,
  children: null,
};

const SupportUsPageRoute = {
  id: "Support Us Page",
  path: SUPPORT_US_PAGE_URL,
  component: SupportUsPage,
  children: null,
};

const unsubscribeLandingPageRoute = {
  id: "Unsubscribe Landing Page",
  path: UNSUBSCRIBE_LANDING_PAGE_URL,
  component: UnsubscribeLandingPage,
  children: null,
};

const mauna2023PageRoute = {
  id: "Mauna 2023 Page",
  path: MAUNA_2023_PAGE_URL,
  component: Mauna2023Page,
  children: null,
};

const mountainMovementFestivalPageRoute = {
  id: "Mountain Movement Festival Page",
  path: MOUNTAIN_MOVEMENT_FESTIVAL_PAGE_URL,
  component: MountainMovementFestivalPage,
  children: null,
};

const movementPlusFestivalPageRoute = {
  id: "Movement Plus Festival Page",
  path: MOVEMENT_PLUS_FESTIVAL_PAGE_URL,
  component: MovementPlusFestivalPage,
  children: null,
};

const ella2023PageRoute = {
  id: "Ella 2023 Page",
  path: ELLA_2023_PAGE_URL,
  component: Ella2023Page,
  children: null,
};

const aurovilleApr2023PageRoute = {
  id: "Auroville Apr 2023 Page",
  path: AUROVILLE_APR_2023_PAGE_URL,
  component: AurovilleApr2023Page,
  children: null,
};

const aoiNov2022PageRoute = {
  id: "AOI November 2022 Page",
  path: AOI_NOV_2022_PAGE_URL,
  component: AOINov2022Page,
  children: null,
};

const facilitatorTraining2023PageRoute = {
  id: "Facilitator Training 2023 Page",
  path: FACILITATOR_TRAINING_2023_PAGE_URL,
  component: FacilitatorTraining2023Page,
  children: null,
};

const aoi2023PageRoute = {
  id: "AOI 2023 Page",
  path: AOI_2023_PAGE_URL,
  component: AOI2023Page,
  children: null,
};

const aoi2024PageRoute = {
  id: "AOI 2024 Page",
  path: AOI_2024_PAGE_URL,
  component: AOI2024Page,
  children: null,
};

const aoi2025PageRoute = {
  id: "AOI 2025 Page",
  path: AOI_2025_PAGE_URL,
  component: AOI2025Page,
  children: null,
};


const inResonanceJams2024PageRoute = {
  id: "In Resonance Jams 2024 Page",
  path: IN_RESONANCE_JAMS_2024_PAGE_URL,
  component: InResonanceJams2024Page,
  children: null,
};

const mountainMovementFestivalJune2024PageRoute = {
  id: "Mountain Movement Festival June 2024 Page",
  path: MOUNTAIN_MOVEMENT_FESTIVAL_JUNE_2024_PAGE_URL,
  component: MountainMovementFestivalJune2024Page,
  children: null,
};

const travellingQuestionsPageRoute = {
  id: "Travelling Questions Page",
  path: TRAVELLING_QUESTIONS_PAGE_URL,
  component: TravellingQuestionsPage,
  children: null,
};

const harvest2024PageRoute = {
  id: "Harvest 2024 Page",
  path: HARVEST_2024_PAGE_URL,
  component: Harvest2024Page,
  children: null,
};

const yogaTTCPageRoute = {
  id: "YogaTTC Page",
  path: YOGA_TTC_PAGE_URL,
  component: YogaTTCPage,
  children: null,
};

const goaEventsPageRoute = {
  id: "Goa Events Page",
  path: GOA_EVENTS_PAGE_URL,
  component: GoaEventsPage,
  children: null,
};

const privacyAndCookiesPageRoute = {
  id: "Privacy And Cookies Page",
  path: PRIVACY_PAGE_URL,
  component: PrivacyAndCookiesPage,
  children: null,
};

const termsAndConditionsPageRoute = {
  id: "Terms And Conditions Page",
  path: TERMS_AND_CONDITIONS_PAGE_URL,
  component: TermsAndConditionsPage,
  children: null,
};

const publicEventsPageRoute = {
  id: "Public Events Page",
  path: EVENTS_PAGE_URL,
  component: PublicEventsPage,
  children: null,
};

const publicPastEventListPageRoute = {
  id: "Public Past Events Page",
  path: EVENTS_PAST_EVENTS_LIST_PAGE_URL,
  component: PublicPastEventListPage,
  children: null,
};

const publicPastEventsCategoryPageRoute = {
  id: "Public Past Events Page",
  path: EVENTS_PAST_EVENTS_CATEGORY_PAGE_URL,
  component: PublicPastEventsCategoryPage,
  children: null,
};

const publicPastEventDetailPageRoute = {
  id: "Public Past Event Detail Page",
  path: EVENTS_PAST_EVENT_DETAIL_PAGE_URL,
  component: PublicPastEventDetailPage,
  children: null,
};

const publicUpcomingEventsPageRoute = {
  id: "Public Upcoming Events Page",
  path: EVENTS_UPCOMING_EVENTS_PAGE_URL,
  component: PublicUpcomingEventsPage,
  children: null,
};

//
const toolsPageRoute = {
  id: "Tools Page",
  path: TOOLS_PAGE_URL,
  component: ToolsPage,
  children: null,
};

const ciftFeedbackPageRoute = {
  id: "CIFT Feedback Page",
  path: CIFT_FEEDBACK_PAGE_URL,
  component: CIFTFeedbackPage,
  children: null,
};

const dharamshala2023FeedbackPageRoute = {
  id: "Dharamshala 2023 Feedback Page",
  path: DHARAMSHALA_2023_FEEDBACK_PAGE_URL,
  component: Dharamshala2023FeedbackPage,
  children: null,
};

const fundRaiser2022PageRoute = {
  id: "Fundraiser 2022 Page",
  path: FUNDRAISER_2022_PAGE_URL,
  component: FundRaiser2022Page,
  children: null,
};

const documentationRoutes = {
  id: "Documentation",
  path: "/documentation",
  header: "Material App",
  icon: <BookOpen />,
  children: [
    {
      path: "/documentation/welcome",
      name: "Welcome",
      component: Welcome,
    },
    {
      path: "/documentation/getting-started",
      name: "Getting Started",
      component: GettingStarted,
    },
    {
      path: "/documentation/environment-variables",
      name: "Environment Variables",
      component: EnvironmentVariables,
    },
    {
      path: "/documentation/deployment",
      name: "Deployment",
      component: Deployment,
    },
    {
      path: "/documentation/theming",
      name: "Theming",
      component: Theming,
    },
    {
      path: "/documentation/state-management",
      name: "State Management",
      component: StateManagement,
    },
    {
      path: "/documentation/api-calls",
      name: "API Calls",
      component: APICalls,
    },
    {
      path: "/documentation/eslint-and-prettier",
      name: "ESLint & Prettier",
      component: ESLintAndPrettier,
    },
    {
      path: "/documentation/support",
      name: "Support",
      component: Support,
    },
  ],
  component: null,
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v2.0.0",
  icon: <List />,
  component: Changelog,
  children: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
const adminDashboardLayoutRoutesInDev = [
  adminHomeRoutes,
  diagnosticsRoute,
  adminUsersRoutes,
  adminUsersEditRegisteredUser,
  adminUsersEditUnregisteredUser,
  adminPostsRoutes,
  adminPostsEditRoute,
  adminEventsRoutes,
  adminEventsEditRoute,
  adminEventsEventDetailsRoute,
  adminEventsEventPhotosRoute,
  adminEventsEventInterestSubmissionsRoute,
  adminEventsConfigureAsUpcomingEventRoute,
  adminEventsConfigureEventPosterRoute,
  dashboardsRoutes,
  pagesRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
];

const adminDashboardLayoutRoutesInProd = [
  adminHomeRoutes,
  diagnosticsRoute,
  adminUsersRoutes,
  adminUsersEditRegisteredUser,
  adminUsersEditUnregisteredUser,
  adminEventsRoutes,
  adminEventsEditRoute,
  adminEventsEventDetailsRoute,
  adminEventsEventPhotosRoute,
  adminEventsEventInterestSubmissionsRoute,
  adminEventsConfigureAsUpcomingEventRoute,
  adminEventsConfigureEventPosterRoute,
];

export const adminDashboardLayoutRoutes =
  process.env.REACT_APP_CODE === "prod"
    ? adminDashboardLayoutRoutesInProd
    : adminDashboardLayoutRoutesInDev;

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Route using the HomePage layout
const homePageLayoutRouteInDev = [
  // oldaHomePageRoute,
  // subscribePageRoute,
  adminEventsEventRegistrationDeskRoute,
];

const homePageLayoutRouteInProd = [
  // oldaHomePageRoute,
  // subscribePageRoute,
  adminEventsEventRegistrationDeskRoute,
];
export const homePageLayoutRoute =
  process.env.REACT_APP_CODE === "prod"
    ? homePageLayoutRouteInProd
    : homePageLayoutRouteInDev;

// Routes using the Presentation layout
// const presentationLayoutRoutesInDev = [
// aboutPageRoute,
// aoiNov2021PageRoute,
// landingRoutes,
// ];
// const presentationLayoutRoutesInProd = [aoiNov2021PageRoute];
// export const presentationLayoutRoutes =
//   process.env.REACT_APP_CODE === "prod"
//     ? presentationLayoutRoutesInProd
//     : presentationLayoutRoutesInDev;

// Route using the PublicPage layout
const publicPageLayoutRouteInDev = [
  homePageRoute,
  aboutUsPageRoute,
  contactUsPageRoute,
  SupportUsPageRoute,
  unsubscribeLandingPageRoute,
  mauna2023PageRoute,
  mountainMovementFestivalPageRoute,
  movementPlusFestivalPageRoute,
  ella2023PageRoute,
  aurovilleApr2023PageRoute,
  aoiNov2022PageRoute,
  facilitatorTraining2023PageRoute,
  aoi2023PageRoute,
  aoi2024PageRoute,
  aoi2025PageRoute,
  inResonanceJams2024PageRoute,
  mountainMovementFestivalJune2024PageRoute,
  travellingQuestionsPageRoute,
  harvest2024PageRoute,
  yogaTTCPageRoute,
  goaEventsPageRoute,
  privacyAndCookiesPageRoute,
  termsAndConditionsPageRoute,
  publicEventsPageRoute,
  publicPastEventListPageRoute,
  publicPastEventsCategoryPageRoute,
  publicPastEventDetailPageRoute,
  publicUpcomingEventsPageRoute,
  publicUpcomingEventsPageRoute,
  toolsPageRoute,
  ciftFeedbackPageRoute,
  dharamshala2023FeedbackPageRoute,
  fundRaiser2022PageRoute,
];

const publicPageLayoutRouteInProd = [
  homePageRoute,
  aboutUsPageRoute,
  contactUsPageRoute,
  SupportUsPageRoute,
  unsubscribeLandingPageRoute,
  aoi2023PageRoute,
  aoi2024PageRoute,
  aoi2025PageRoute,
  inResonanceJams2024PageRoute,
  mountainMovementFestivalJune2024PageRoute,
  travellingQuestionsPageRoute,
  privacyAndCookiesPageRoute,
  termsAndConditionsPageRoute,
  publicEventsPageRoute,
  publicPastEventListPageRoute,
  publicPastEventsCategoryPageRoute,
  publicPastEventDetailPageRoute,
  publicUpcomingEventsPageRoute,
  publicUpcomingEventsPageRoute,
  toolsPageRoute,
  ciftFeedbackPageRoute,
  dharamshala2023FeedbackPageRoute,
];
export const publicPageLayoutRoutes =
  process.env.REACT_APP_CODE === "prod"
    ? publicPageLayoutRouteInProd
    : publicPageLayoutRouteInDev;

// Routes using the Mover layout
const moverLayoutRouteInDev = [moverProfileRoutes];
const moverLayoutRoutesInProd = [];
export const moverLayoutRoutes =
  process.env.REACT_APP_CODE === "prod"
    ? moverLayoutRoutesInProd
    : moverLayoutRouteInDev;

// Routes that are protected
const protectedRoutesInDev = [protectedPageRoutes];
const protectedRoutesInProd = [];
export const protectedRoutes =
  process.env.REACT_APP_CODE === "prod"
    ? protectedRoutesInProd
    : protectedRoutesInDev;

// Routes visible in the sidebar

const sidebarRoutesInDev = [
  adminHomeRoutes,
  adminUsersRoutes,
  adminPostsRoutes,
  adminEventsRoutes,
  diagnosticsRoute,
  dashboardsRoutes,
  pagesRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  authRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
];

const sidebarRoutesInProd = [
  adminHomeRoutes,
  adminUsersRoutes,
  adminEventsRoutes,
  diagnosticsRoute,
];

export const sidebarRoutes =
  process.env.REACT_APP_CODE === "prod"
    ? sidebarRoutesInProd
    : sidebarRoutesInDev;
