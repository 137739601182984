/* External Links */
export const INCONTACT_YOUTUBE_URL =
  "https://www.youtube.com/@incontactcollective";

export const INCONTACT_FACEBOOK_URL =
  "https://www.facebook.com/contactimprovindia/";

export const INCONTACT_INSTAGRAM_URL =
  "https://www.instagram.com/incontact.india/";

export const INCONTACT_TELEGRAM_URL = "https://t.me/+DkIq_ig9AS45Y2Vl";

/* Internal Links For Public Pages */

export const HOME_PAGE_URL = "/";

export const ABOUT_US_PAGE_URL = "/about-us";

export const CONTACT_US_PAGE_URL = "/contact-us";

export const EVENTS_PAGE_URL = "/events";

export const EVENTS_PAST_EVENTS_LIST_PAGE_URL = "/events/past/";

export const EVENTS_PAST_EVENTS_CATEGORY_PAGE_URL =
  "/events/past-events-category/";

export const EVENTS_PAST_EVENT_DETAIL_PAGE_URL = "/events/past/event";

export const EVENTS_UPCOMING_EVENTS_PAGE_URL = "/events/upcoming/";

export const UNSUBSCRIBE_LANDING_PAGE_URL = "/system/optout";

export const TOOLS_PAGE_URL = "/resources";

export const SUPPORT_US_PAGE_URL = "/support-us";

export const CIFT_FEEDBACK_PAGE_URL = "/tools/cift-feedback";

export const DHARAMSHALA_2023_FEEDBACK_PAGE_URL = "/tools/participant-feedback";

export const YOGA_TTC_PAGE_URL = "/events/yoga-ttc";

export const AOI_NOV_2022_PAGE_URL = "/events/aoi-nov-2022";

export const FACILITATOR_TRAINING_2023_PAGE_URL =
  "/events/facilitator-training-2023";

export const MAUNA_2023_PAGE_URL = "/events/mauna-2023";

export const MOUNTAIN_MOVEMENT_FESTIVAL_PAGE_URL =
  "/events/mountain-movement-festival";

export const MOUNTAIN_MOVEMENT_FESTIVAL_JUNE_2024_PAGE_URL =
  "/events/mountain-movement-festival-june-2024";

export const AOI_2023_PAGE_URL = "/events/aoi-2023";

export const AOI_2024_PAGE_URL = "/events/aoi-2024";

export const AOI_2025_PAGE_URL = "/events/aoi-2025";

export const TRAVELLING_QUESTIONS_PAGE_URL = "/events/travelling-questions";

export const MOVEMENT_PLUS_FESTIVAL_PAGE_URL = "/events/movement-plus-festival";

export const HARVEST_2024_PAGE_URL = "/events/harvest-2024";

export const IN_RESONANCE_JAMS_2024_PAGE_URL = "/events/in-resonance-jams-2024";

export const ELLA_2023_PAGE_URL = "/events/ella-2023";

export const AUROVILLE_APR_2023_PAGE_URL = "/events/auroville-apr-2023";

export const GOA_EVENTS_PAGE_URL = "/goa-events";

export const TERMS_AND_CONDITIONS_PAGE_URL = "/terms";

export const FUNDRAISER_2022_PAGE_URL = "/events/fundraiser-2022";

export const PRIVACY_PAGE_URL = "/privacy";

export const SIGN_IN_PAGE_URL = "/auth/sign-in";

/* Internal Links For Admin Pages */

export const ADMIN_HOME_URL = "/admin";
